
import { 
  getSaleList,
  getMatkl,
  getproductSeach,
  hasLockPrice,
  hasUnLockPriceBy,
  getCustCisCode,
  savePrice,
  saveAndPublish,
  checkValid,
  getEditInfo,
  updateAndPublish,
  updatePrice

} from './api'
import moment from 'moment';
export default {
  components: {
  },
  name: "",
  data() {
    return {
      breadcrumbData: [{
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/productChannelWholesalePrice/productChannelPriceList",
          name: "productChannelPriceList",
          title: "渠道价格列表"
        },
        {
          path: "/productChannelWholesalePrice/productChannelPriceadd",
          name: "addOredit",
          title: "渠道价格新增"
        }
      ],
      pageLoadFlag: false,
      orgList:[], // 组织数据
      orgCode:'', //组织code
      MatklList:[],//物料组
      MatklCode:'',// 物料组code
      timeStart:'', // 开始时间
      endTime:'', // 结束时间
      productList:[], // 产品型号
      proctText:'', // 输入的检索条件
      qudaoPrice:'',// 渠道批发价
      ratailPrice:'',//零售价
      zhidaoprice:'', // 海信是否有指导价
      samePrice:'',// 海信是否有统一价
      FXteshuPrice:'',//分销特殊价
      SjText:'',// 商家模糊搜索数据
      sjList:[] , // 商家数据
      isLoading:false,
      disabledButton:false,
      showBussiness:true,
      orgName:'',
      MatklName:'',
      id:'',
      buinessFe:false
    };
  },
  watch: {
     
  },

  mounted() {
    
    if(this.$route.query && this.$route.query.type == 'edit') {
      this.getInfo(this.$route.query.id)
      this.id = this.$route.query.id
      this.showBussiness = false
    } else {
      this.getAfentOrg()
      this.showBussiness = true
    }
  },
  
  methods:{
    /* 以下是编辑代码 */
    getInfo(id) {
      let data = {
        id:id
      }
      getEditInfo(data).then(res => {
        if(res.data && res.data.status == 0) {
          this.orgCode = res.data.data.orgName
          this.orgName = res.data.data.orgId // 组织id
          this.MatklCode = res.data.data.matklName
          this.MatklName = res.data.data.matklId // 物料组id
          this.timeStart = res.data.data.startDate // 开始时间
          this.endTime = res.data.data.endDate // 结束时间
          this.proctText = res.data.data.productModelName
          //  编辑的时候商家编码不显示
          this.showBussiness = false
          this.qudaoPrice = res.data.data.channelPrice // 渠道批发价格
          this.ratailPrice = res.data.data.retailPrice // 零售价格
          // this.hasLockPriceedit()
          // this.hasUnLockPriceByedit()
          this.cherkPrice()
        } else {
          this.$message.warning('获取详情失败',res.data.msg)
        }
      })
    },
    // 修改
    changePrice() {
      this.pageLoadFlag = true
      let data = {
        id: this.id,
        channelPrice: this.qudaoPrice,
        retailPrice: this.ratailPrice,
        startDate: this.timeStart,
        endDate: this.endTime,
      }
      updatePrice(data).then(res =>{
        if(res.data.code == 0){
          this.$message.success('保存修改成功')
          this.pageLoadFlag = false
          // 关闭窗口之前刷新父页面
          window.opener.location.reload();
          this.timer = setTimeout(()=>{  //设置延迟执行
            this.goback()
          },2000);
        }
      })
    },
    // 修改并发布
    updateAndPublish(){
      this.pageLoadFlag = true
      let data = {
        id: this.id,
        channelPrice: this.qudaoPrice,
        retailPrice: this.ratailPrice,
        startDate: this.timeStart,
        endDate: this.endTime,
      }
      updateAndPublish(data).then(res=>{
        if(res.data.code == 0){
          this.$message.success('保存并发布成功')
          this.pageLoadFlag = false
          // 关闭窗口之前刷新父页面
          window.opener.location.reload();
          this.timer = setTimeout(()=>{  //设置延迟执行
            this.goback()
          },2000);
        }
      })
    },
    /* 编辑代码结束 */
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    // 返回
    goback(){
      window.close()
      this.$router.push({ path:'/productChannelWholesalePrice/productChannelPriceList'});
    },
    
    /* 以下是新增代码 */
    // 获取销售组织数据
    getAfentOrg(){
      let data = {}
      getSaleList(data).then(res=>{
        this.orgList = res.data.list
      })
    },
    // 更改销售组织查询物料组数据
    changeOrg(){
      let data = {
        orgId :this.orgCode
      }
      getMatkl(data).then(res=>{
        this.MatklList = res.data.list
      })
    },
    // 更改物料组织的时候清空产品列表
    changeMartl(){
      this.proctText = ''
      this.productList = []
    },
    // 更改开始时间
    handleTimeChange(e, defaultTime){
      this.timeStart = defaultTime;
      this.cherkPrice()
    },
    // 开始时间选择不能选择今天之前的日期
    disabledDate(current) {
      return current && current < moment().startOf('day');
    },
    // 更改结束时间
    handleendTimeChange(e, defaultTime){
      this.endTime = defaultTime;
      this.cherkPrice()
    },
    // 产品型号搜索
    changeProotext(){
      this.proctText = this.proctText.replace(/[, ]/g,'')
      this.cherkPrice()
    },
 
     // 输入模糊搜索代理商
    getProductList(value) {
      this.buinessFe = true
      this.proctText = value
      let data = {
        term:this.proctText,
        matklId:this.MatklCode
      }
      getproductSeach(data).then(res => {
        let data;
        data = res.data;
        this.productList = [...new Set(data)]; //缓存列表数据，前端分页
        this.buinessFe = false
      })
    },

    search(){
      // this.cherkPrice()
    },
    // 获取商家编码
    // getSJList(value){
    //   this.SjText = value
    //   let data = {
    //     term:this.SjText,
    //     matklId:this.MatklCode,
    //     orgId:this.orgCode,
    //
    //   }
    //   getCustCisCode(data).then(res => {
    //     let data;
    //     data = res.data;
    //     this.sjList = [...new Set(data.list)]; //缓存列表数据，前端分页
    //   })
    // },
    // 检查是都有海信指导价和统一价
    cherkPrice(){
      if(!this.orgCode || !this.MatklCode || !this.proctText || !this.timeStart || !this.endTime){
        return
      }
      this.hasLockPrice() 
      this.hasUnLockPriceBy()
      this.getcheckValid()
    },
    // 是否有海信统一价 
    hasLockPrice() {
      let data = {}
      if(this.showBussiness){
        data = {
          productName:this.proctText,
          orgId:this.orgCode,
          start:this.timeStart,
          end:this.endTime
        }
      }else{
        data = {
          productName:this.proctText,
          orgId:this.orgName,
          start:this.timeStart,
          end:this.endTime
        }
      }
      hasLockPrice(data).then(res=>{
        if(res && res.data.length>0){
          this.samePrice = 'Y'
        } else {
          this.samePrice = 'N'
        }
      })
    },
    // 查询是否有海信指导价
    hasUnLockPriceBy(){
      let data = {}
      if(this.showBussiness){
        data = {
          productName:this.proctText,
          orgId:this.orgCode,
          start:this.timeStart,
          end:this.endTime
        }
      }else{
        data = {
          productName:this.proctText,
          orgId:this.orgName,
          start:this.timeStart,
          end:this.endTime
        }
      }
      hasUnLockPriceBy(data).then(res=>{
        if(res && res.data.length>0){
          this.zhidaoprice = 'Y'
        } else {
          this.zhidaoprice = 'N'
        }
      })
    },
    // 更改搜索条件查询
    changeSjtext(){
    },
    // 查询渠道批发价
    getcheckValid() {
      let data = {}
      if(this.showBussiness){
        data = {
          orgId:this.orgCode,
          matklId:this.MatklCode,
          productName:this.proctText,
          startDate:this.timeStart,
          endDate:this.endTime,
        }
      }else{
        data = {
          orgId:this.orgName,
          matklId:this.MatklName,
          productName:this.proctText,
          startDate:this.timeStart,
          endDate:this.endTime,
          priceId:this.id
        }
      }
      if(!this.showBussiness){
        data.priceId=this.id
      }
      checkValid(data).then(res=>{
        if(res.data.code == 0) {
          this.disabledButton = false
        } else {
          this.disabledButton = true
          this.$message.warning(res.data.msg)
        }
      })
    },
  //  存Draft为草稿
    saveDraft(type) {
      if(!this.orgCode) {
        this.$message.warning('请选择销售组织')
        return
      }
      if(!this.MatklCode) {
        this.$message.warning('请选择物料组')
        return
      }
      if(!this.timeStart) {
        this.$message.warning('请选择开始时间')
        return
      }
      if(!this.endTime) {
        this.$message.warning('请选择结束时间')
        return
      }
      if(!this.proctText) {
        this.$message.warning('请选择产品型号')
        return
      }
      if(!this.qudaoPrice) {
        this.$message.warning('请填写渠道批发价')
        return
      }
      if(!this.ratailPrice) {
        this.$message.warning('请填写零售价')
        return
      }

      this.pageLoadFlag = true
      let data = {
        orgId:this.orgCode,
        productName:this.proctText,
        channelPrice:this.qudaoPrice,
        retailPrice:this.ratailPrice,
        startDate:this.timeStart,
        endDate:this.endTime,
      }
      if(type == 'draft'){
        savePrice(data).then(res => {
          if(res.data.code == 0) {
            this.$message.success('保存草稿成功')
            this.pageLoadFlag = false
            // 关闭窗口之前刷新父页面
            window.opener.location.reload();
            this.timer = setTimeout(()=>{  //设置延迟执行
              this.goback()
            },2000);
          } else {
            this.$message.warning('保存失败',res.data.msg)
            this.pageLoadFlag = false
          }
        })
      }
      if(type == 'save') {
        saveAndPublish(data).then(res => {
          if(res.data.code == 0) {
            this.$message.success('保存并发布成功')
            this.pageLoadFlag = false
            // 关闭窗口之前刷新父页面
            window.opener.location.reload();
           this.timer = setTimeout(()=>{  //设置延迟执行
             this.goback()
           },2000);
            
          } else {
            this.$message.warning('保存并发布失败',res.data.msg)
            this.pageLoadFlag = false
          }
        })
      }

    },
    /* 新增结束 */
  }
  
};